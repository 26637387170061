<template>
    <div class="stage-inner-container-padded mt-2">
        <b-row>
            <b-col cols="12" lg="6" order-lg="1">
                <div class="d-flex flex-column shadow mt-2">
                    <div
                        :class="`bg-info px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
                    >
                        Task Information
                    </div>
                    <div :class="`pt-1 px-1 rounded-bottom border border-info`">
                        <!-- Task Name -->
                        <div>
                            <span class="font-weight-bold">Name:</span> {{name}}
                        </div>
                        <div>
                            <span class="font-weight-bold">Description:</span> {{description}}
                        </div>
                        <div>
                            <span class="font-weight-bold">Technician:</span> 
                            <span v-if="technician != null">{{technician}} <span v-if="techCompany != null">- {{techCompany}}</span></span>
                            <span v-else>Technician Not Specified</span>
                        </div>
                        <div v-if="signature != null || customerSignature != null">
                            <hr>
                        </div>
                        <!-- Signatures -->
                        <div class="d-flex flex-row">
                            <div v-if="signature != null" class="d-flex flex-column">
                                <div class="text-center">
                                    <span class="font-weight-bold">Technician Signature</span>
                                </div>
                                <hover-image-with-modal
                                    :imgID="signature"
                                ></hover-image-with-modal>
                            </div>
                            <div v-if="customerSignature != null" class="d-flex flex-column">
                                <div class="text-center">
                                    <span class="font-weight-bold">Customer Signature</span>
                                </div>
                                <hover-image-with-modal
                                    :imgID="customerSignature"
                                ></hover-image-with-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" lg="6" order-lg="2">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column shadow mt-2">
                        <div
                            :class="`bg-info px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
                        >
                            Attachments
                        </div>
                        <div v-if="attachments.length > 0" :class="`pt-1 px-1 rounded-bottom border border-info`">
                            <b-input-group size="sm">
                                <b-form-input id="input" placeholder="Search Attachments" v-model="searchTerm"></b-form-input>
                                <b-input-group-append>                    
                                    <b-button 
                                        text="Button" 
                                        variant="success"
                                        @click="performSearch"
                                    >
                                        <b-icon-search></b-icon-search>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <b-table
                                striped
                                :items="searchResults"
                                :fields="fields"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                stacked="md"
                                small
                            >
                                <template #cell(score)="data">
                                    {{(data.item.score * 100).toFixed(1)}}%
                                </template>
                                <template #cell(actions)="data">
                                    <b-button-toolbar>
                                        <b-button-group>
                                            <b-button
                                                title="Download Attachment"
                                                class="vai-flat-btn px-2"
                                                size="sm"
                                                variant="info"
                                                @click="download(data.item)"
                                                :disabled="data.item.fetching"
                                            >
                                                <b-icon v-if="!data.item.fetching" icon="download" font-scale="1" scale="1"></b-icon>
                                                <b-spinner v-else small></b-spinner>
                                            </b-button>
                                        </b-button-group>
                                    </b-button-toolbar>
                                </template>
                            </b-table>
                        </div>
                        <div v-else :class="`pt-1 px-1 rounded-bottom border border-info`">
                            No Attachments
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" lg="6" order-lg="2">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column shadow mt-2">
                        <div
                            :class="`bg-info px-3 fs-1-2-5 font-weight-bold py-2 rounded-top`"
                        >
                            Photos
                        </div>
                        <div v-if="photos.length > 0" :class="`pt-1 px-1 rounded-bottom border border-info`">
                            <div v-for="photo in photos" :key="photo.id" class="d-flex flex-row">
                                <div>
                                    <hover-image-with-modal
                                        :imgID="photo.id"
                                    ></hover-image-with-modal>
                                </div>
                                <div class="d-flex flex-column">
                                    <div>
                                        <span class="font-weight-bold">Caption:</span>
                                    </div>
                                    <div>
                                        {{photo.comment}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else :class="`pt-1 px-1 rounded-bottom border border-info`">
                            No Photos
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;
var fileDownload = require('js-file-download');
import Fuse from 'fuse.js'

import image from '@/components/subcomponents/Image.vue'

export default {
    name: 'GenericTaskViewer',
    components:{
        'hover-image-with-modal': image,
    },
    props:{
        eventID: String
    },
    data(){
        return{
            name: null,
            description: null,
            attachments: [],
            photos: [],
            eventTstamp: null,
            technician: null,
            techCompany: null,
            signature: null,
            customerSignature: null,
            // Instance Stuff
            sortBy: 'index',
            sortDesc: false,
            psSortBy: null,
            psSortDesc: true,
            waiting: false,
            searchResults: [],
            fields: [
                {
                    key: 'index',
                    label:"Index",
                    sortable: true
                },
                {
                    key: 'comment',
                    label:"Comment",
                    sortable: true
                },
                {
                    key: 'attachment_type_name',
                    label:"Type",
                    sortable: true
                },
                {
                    key: 'note',
                    label:"Filename",
                    sortable: true
                },
                {
                    key: 'actions',
                    label:"Actions",
                    sortable: false
                },
            ],
            searchTerm: null,
            fuse: null,
        }
    },
    methods:{
        performSearch(){
            if(this.searchTerm == null || this.searchTerm == ''){
                // Reset Attachment Table Content
                this.searchResults = this.attachments;
                this.fields = _.filter(this.fields, (fld)=>{ return fld.key != "score" });
                this.sortBy = this.psSortBy;
                this.sortDesc = this.psSortDesc;
            }else{
                if(_.find(this.fields, (fld)=>{ return fld.key == "score" }) == undefined){
                    this.fields.unshift({
                        key: 'score',
                        label: 'Search Score',
                        sortable: true
                    });
                    this.psSortBy = this.sortBy;
                    this.psSortDesc = this.sortDesc;
                    this.sortBy = "score";
                    this.sortDesc = true;
                }
                // A search term exits, peform the search
                var result = this.fuse.search(this.searchTerm);
                // This function will normalize a value
                function normalize(val, max, min){
                    return (val - min)/(max - min)
                }
                this.searchResults = result.map((row)=>{ 
                    return {
                        ...row.item,
                        score: normalize(row.score, 0, 1)
                    }
                })
            }
        },
        download(attachment){
            attachment.fetching = true;
            butils.customInstance.timeoutAndBlob(120000).get(`${process.env.VUE_APP_FILE_API_BASE_URL}/${attachment.file_id}`)
            .then((response)=>{
                attachment.fetching = false;
                fileDownload(response.data, attachment.note);
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To Donwload Attachment Is Denied', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Downloading File', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
    },
    watch:{
        searchTerm: function(newVal, oldVal){
            this.performSearch(newVal);
        }
    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/generic_task_entries/${this.eventID}`)
        .then((response)=>{
                let genericTask = response.data.result;
                console.log(genericTask)
                this.name = genericTask.name;
                this.description = genericTask.description;
                this.eventTstamp = genericTask.event_tstamp;
                this.technician = genericTask.technician_name;
                this.techCompany = genericTask.tech_company;
                this.signature = genericTask.signature;
                this.customerSignature = genericTask.customer_signature;

                if(genericTask.photos != null){
                    this.photos = genericTask.photos;
                }else{
                    this.photos = [];
                }
                
                if(genericTask.attachments != null){
                    genericTask.attachments.forEach((attachment)=>{
                        attachment.added_on = butils.formatters.timestampToDateTime(attachment.added_on, true, false);
                        attachment.fetching = false;
                    })
                    this.attachments = genericTask.attachments;
                }else{
                    this.attachments = [];
                }
                // Setup Fuse.js Search
                this.fuse = new Fuse(this.attachments, { includeScore: true, keys: [ 'note', 'comment', 'attachment_type_name', 'added_on', 'added_by_name']})
                this.searchResults = this.attachments;
            })
            .catch((err)=>{
                butils.apiRequestErrorHandling(err, this, 'Fetching Generic Task Data', true)
            })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>